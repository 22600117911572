/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        var btnToComments = document.querySelector('.to-comments');

        btnToComments.addEventListener('click', (e)=> {
            ScrollToForm()
        })


        /*
        |
        | Scroll to form
        |
        */
        const ScrollToForm = () => {
            TweenLite.to(window, .5, {scrollTo:{y:$(".comment-form-comment").offset().top}});
        }


        /*
        |
        | Copy element
        |
        */
        // const createElementToCopy = (comment) => {

        //     let headerComment = comment.querySelector('.comment-meta'),
        //         bodyComment = comment.querySelector('.comment-content'),
        //         headerCopied = headerComment.cloneNode(true),
        //         bodyCopied = bodyComment.cloneNode(true),
        //         commentCopy = document.createElement("div");

        //         commentCopy.setAttribute('class', 'comment comment-copied no-border')
        //         commentCopy.appendChild(headerCopied)
        //         commentCopy.appendChild(bodyCopied)

        //     return commentCopy;
        // }

        /*
        |
        | Select element to copy
        |
        */
        // const   comments = document.querySelectorAll('.comment'),
        //         formContainer = document.querySelector('.comment-respond');


        // comments.forEach( comment => {

        //     let replyBtn = comment.querySelector('.comment-reply-link');

        //     replyBtn.addEventListener('click', () => {
        //         let commentCopied = document.querySelector('.comment-copied')

        //         if (commentCopied){
        //             formContainer.removeChild(commentCopied);
        //         }

        //         let appendTo = document.querySelector('.comment-respond');
        //         appendTo.appendChild(createElementToCopy(comment));

        //     })

        // })

    }
}
