/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, Plyr) => {

        /*
        |
        | Filters open Dropdown
        |------------------------
        */
        let xhr = null;

        const   postsContainer = document.querySelector('#posts-container'),
                post_type = document.querySelector('[data-post]').getAttribute('data-post'),
                loader = document.querySelector('.loader-ajax'),
                pagination = document.querySelector('.pagination');

        let filters = document.querySelectorAll('.filter'),
            ajaxObject = {
                // month : '',
                // category : '',
                // order : '',
                // taxoCategoriesName : post_type + '-categories',
            }

        let allTimelines = [];

        if(filters){
            filters.forEach( (filter, indexClicked) => {
                let dropdown = filter.querySelector('.dropdown-list'),
                    dropdownContainer = filter.querySelector('.dropdown-container'),
                    arrow = filter.querySelector('.arrow-nav-drop'),
                    items = filter.querySelectorAll('.filter-item');

                let tlFilter = new TimelineMax({paused: true});
                tlFilter.from(dropdown, .2, {height: 0}, 'start');
                tlFilter.set(dropdown, {autoAlpha : 1}, 'start')
                tlFilter.to(arrow, .2, {rotation: -90}, 'start');

                allTimelines.push(tlFilter);

                dropdownContainer.addEventListener('click', () => {

                    allTimelines.forEach( (tl, i) => {
                        if( indexClicked !== i || tl.progress() === 1){
                            tl.reverse();
                        } else {
                            tl.play();
                        }
                    })

                })

                /*
                | Click item
                | ----------------------
                | => reverse animation
                | => Start Ajax Call
                */
                items.forEach( item => {
                    item.addEventListener('click', () => {

                        // Add Selected on item clicked
                        if(item.classList.contains('selected')){
                            item.classList.remove('selected');
                        } else {
                            items.forEach( item => {
                                item.classList.remove('selected');
                            })
                            item.classList.add('selected');
                        }

                        tlFilter.reverse();

                        let location = window.location,
                            url = location.origin + location.pathname

                        document.querySelectorAll('.filter-item.selected').forEach(function(filter, index) {
                            let value = filter.getAttribute('data-value')
                            let key = filter.getAttribute('data-key')
                            ajaxObject[key] = value

                            url += (index < 1) ? '?' : '&'
                            url += key +'='+ value
                        })

                        window.location.href = url

                        // if(ajaxObject.season || ajaxObject.category || ajaxObject.order){
                        //     callAjax(ajaxObject);
                        // } else {
                        //     window.location.reload()
                        // }
                    })
                })
            })
        }

        const callAjax = (ajaxObject) => {


            if (xhr !== null) {
                xhr.abort()
            }

            //Vide le DOM
            postsContainer.innerHTML = "";
            loader.style.display = "block";

            const route = `/ajax/archive/${post_type}`;

            xhr = $.ajax({
                url: route,
                type: 'GET',
                data: ajaxObject,
                success: (response, status) => {

                    //Rempli le DOM
                    loader.style.display = "none";
                    postsContainer.innerHTML = response;
                    pagination.style.display = "none";

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })


        }



    }
}
