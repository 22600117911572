/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TimelineMax } from "gsap";

export default {
	init: (app) => {

        const cards = document.querySelectorAll(".card-item");

        cards.forEach( element => {

            const content = element.querySelector(".content-height");
            const colorContainer = element.querySelector(".content")
            const color = element.querySelector('[data-color]').getAttribute("data-color");
            const animInside = element.querySelectorAll(".item-inside");
            
            const tlCard = new TimelineMax({paused: true});
            tlCard.from(content, .3,{height: 0}, "start");
            tlCard.from(content, .1, {autoAlpha: 0}, "start");
            tlCard.to(colorContainer, .5, {backgroundColor: color}, "start")
            tlCard.staggerFrom(animInside, .2, {autoAlpha: 0, y: 20}, .01, "start+=.2")

            element.addEventListener('mouseenter', () => {
                tlCard.play();
            })

            element.addEventListener('mouseleave', () => {
                tlCard.reverse();
            })

        })

    }
}
