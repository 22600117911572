/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import Plyr from 'plyr';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';

// import search from '@components/search.js';
import cardItem from '@components/cardItem.js';

/*
|
| Importing Utils
|-------------------
*/
// import '@utils/fa';
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import sample from '@pages/sample.js';
import contact from '@pages/contact.js';
import forum from '@pages/forum.js';
import archive from '@pages/archive.js';


/*
|
| Routing
|----------
*/
const routes = new Router([
    {
      'file': animations,
      'dependencies': [app, Menu, Kira, ScrollMagic]
    },
	  {
		'file': main,
		'dependencies': [app, CookieManager, Swiper, Plyr]
    },
    {
		'file': news,
		'dependencies': [app],
		'resolve': '#page-news-archive'
    },
	  {
		'file': sample,
		'dependencies': [app],
		'resolve': '#page-sample'
    },
    {
		'file': forum,
		'dependencies': [app],
		'resolve': '#page-forum-single'
    },
    {
    'file': archive,
    'dependencies': [app],
    'resolve': '#page-archive'
    },
    {
		'file': cardItem,
		'dependencies': [app],
		'resolve': '.card-container'
    },
    // {
    //   'file': search,
    //   'dependencies': [app],
    //   'resolve': '#page-archive'
    //   },
    {
      'file': contact,
      'dependencies': [app],
      'resolve': '#page-contact'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

