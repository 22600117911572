/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, CookieManager, Swiper, Plyr) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body            = $('body'),
            bigSlider        = document.querySelectorAll('.big-slider'),
            videoArrow       = document.querySelectorAll('.video-arrow'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
		;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });



        /*
		|
		| Header
		|----------
		*/
        const menuItems = document.querySelectorAll('.menu-item-has-children');
        
        menuItems.forEach( item => {

            const navDrop = item.querySelector('.nav-drop');
            const navArrow = item.querySelector('.arrow-nav-drop')

            var opened = false;

            var tlMenu = new TimelineMax({paused: true});

            // tlMenu.fromTo(navDrop, .1, {display: "none"},{ display: 'flex'});
            tlMenu.from(navDrop, .1, { height: 0});
            tlMenu.to(navDrop, .3, {autoAlpha: 1, y: 0}, 'start');
            tlMenu.to(navArrow, .3, {rotation: -90}, 'start');

            item.addEventListener('mouseenter', () => {
                tlMenu.play();
            })

            item.addEventListener('mouseleave', () => {
                tlMenu.reverse();
            })

            //Mobile touch arrow
            navArrow.addEventListener('click', () => {
                if (!opened){
                    tlMenu.play();
                    opened = true;
                } else {
                    tlMenu.reverse();
                    opened = false;
                }
            })

        })


        /*
		|
		| Big slider
		|-----------
        */
        bigSlider.forEach( element => {
            const swiper = element.querySelector('.swiper-container');
            const arrowNext = element.querySelector('.swiper-arrow-next');
            const arrowPrev = element.querySelector('.swiper-arrow-prev');

            var slider = new Swiper(swiper, {
                slidesPerView : 'auto',
                centeredSlides : true,
                spaceBetween : 30,
                navigation: {
                    nextEl: arrowNext,
                    prevEl: arrowPrev,
                }
            })

            slider.on('slideChange', () => {
                videoArrow.forEach(arrow => {
                    TweenLite.to(arrow, .3, {autoAlpha : 1});
                })
            })
        })


        /*
		|
		| Video of Slider
		|------------------
        */
        const videoSlide = document.querySelectorAll('.video-slide');

        videoSlide.forEach( (item) => {
            let video = new Plyr(item, {
                'clickToPlay' : true,
            });

            item.addEventListener('play', ()=> {
                videoArrow.forEach(arrow => {
                    TweenLite.to(arrow, .3, {autoAlpha : 0});
                })                
            })
            
            item.addEventListener('pause', ()=> {
                videoArrow.forEach(arrow => {
                    TweenLite.to(arrow, .3, {autoAlpha : 1});
                })
            })
        })
        


        /*
		|
		| Small slider icons
		|----------------------
        */
        const smallSliders = document.querySelectorAll('.small-slider .swiper-container');

        smallSliders.forEach( slider => {

            const prevArrow = slider.parentNode.querySelector('.slider-button-prev');
            const nextArrow = slider.parentNode.querySelector('.slider-button-next')

            new Swiper(slider, {
                slidesPerView : 2.5,
                loop: true,
                autoplay: {
                    delay: 2000,
                },
                breakpoints: {
                    992: {
                      slidesPerView: 6,
                      spaceBetween: 20
                    },
                },
                navigation: {
                    nextEl: nextArrow,
                    prevEl: prevArrow,
                }
            })
        })


        /*
		|
		| Close Login modal
		|-----------
        */
        const loginBtn = $('.login-btn');
        const loginModal = document.querySelector('.login-modal');
        const overlay = $('.overlay');

        const tlLogin = new TimelineMax({paused: true});

        tlLogin.set(loginModal, {display: "flex"});
        tlLogin.from(overlay, .3, {autoAlpha: 0})
        tlLogin.from(loginModal, .3, {autoAlpha: 0, y: 10});

        if(loginBtn !== null){
            loginBtn.on('click', (e) => {
                e.preventDefault();
                tlLogin.play();
                $body.css({
                    overflow : "hidden",
                })
            })
        }

        $('.overlay, .close').on('click', () => {
            tlLogin.reverse();
            $body.css({
                overflow : "auto",
            })
        })



        /*
		|
		| Login
		|-----------
        */

        // const loginModalInner = document.querySelector('.login-modal-inner');
        // var tlLoginFailed = new TimelineMax({paused : true});
        // tlLoginFailed.to(loginModalInner, .05, {scale : 1.02, clearProps:"scale", ease: Elastic.easeOut}, "animationStart")
        // tlLoginFailed.to(loginModalInner, .03, {rotation:"-=1", yoyo:true, repeat:11, ease: Elastic.easeOut, onComplete:function(){
        //     tlLoginFailed.to(loginModalInner, .1, {scale : 1})
        //     tlLoginFailed.to(loginModalInner, .05, {rotation:"0"})
        // }},'animationStart-=.1')


        $('form#beautiful-login').on('submit', function(e){

            //Show messages
            $('form#beautiful-login .verify').show().text(beautiful_login_object.loadingmessage);
            $.ajax({
                type: 'POST',
                dataType: 'json',
                url: beautiful_login_object.ajaxurl,
                data: { 
                    'action': 'ajaxlogin',
                    'log': $('form#beautiful-login #username').val(), 
                    'pwd': $('form#beautiful-login #password').val(), 
                    'security': $('form#beautiful-login #security').val()
                },
                success: function(data){

                    $('.verify').hide();

                    //Successed authentification
                    if (data.loggedin == true){
                        $('#beautiful-login .input-modal').removeClass('error');
                        $('form#beautiful-login .error').hide();
                        $('form#beautiful-login .verify').html(data.message);
                        document.location.href = beautiful_login_object.redirecturl;
                        
                    //Failed authentification
                    } else {
                        $('form#beautiful-login .error').html(data.message);
                        $('#beautiful-login .input-modal').addClass('error');

                        //Empty password
                        $('form#beautiful-login #password')[0].value = "";
                        
                        // tlLoginFailed.time(0);
                        // tlLoginFailed.play();
                    }
                }
            });
            e.preventDefault();
        });

        $body.on('loaderEnd', () => console.log('ended'))
	}
}
